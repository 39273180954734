
 import $ from "jquery";
const accordion = (() => {
  const defaultAccordion = () => {
    const accordionItem = document.getElementsByClassName('js-trigger-accordion');

    for (let i = 0; i < accordionItem.length; i += 1) {
      accordionItem[i].addEventListener('click', function (event) {
        event.currentTarget.classList.toggle('js-active');
        const accordionContent = this.nextElementSibling;
        event.currentTarget.querySelector('.c-accordion__icon svg').classList.toggle('js-active');
        accordionContent.classList.toggle('js-active');
      });
    }
  };

  const newsFilter = () => {
    const listLi = document.getElementsByClassName("select-filter-li");
    for (let i = 0; i < listLi.length; i += 1) {
      listLi[i].addEventListener('click', function (event) {
        let eventParent = this.parentNode.parentNode.querySelector("span")
        let eventText = event.currentTarget.innerHTML;
        eventParent.innerHTML = eventText;
        for (let k = 0; k < listLi.length; k++) {
          listLi[k].classList.remove('active');
        }
        event.currentTarget.classList.toggle('active');
      });
    }
  };

  const accesoryFilter = () => {
    $(".mobile-filter-btn").on("click", function(){
      $(".acc-left-menu").toggleClass("active");
      $("html,body").addClass("hidden");
    });
    $(".mobile-filter-close").on("click", function(){
      $(".acc-left-menu").removeClass("active");
      $("html,body").removeClass("hidden");
    });
  };

  const accesoryTab = () => {
    const accListLi = document.getElementsByClassName("tab-lk");
    for (let i = 0; i < accListLi.length; i += 1) {
      accListLi[i].addEventListener('click', function (event) {
        var hrefLi = $(this).attr("href");
        $(".tab-dt-item").removeClass("active");
        $(hrefLi).addClass("active");
        for (let k = 0; k < accListLi.length; k++) {
          accListLi[k].classList.remove('active');
        }
        event.currentTarget.classList.toggle('active');
      });
    }
    $("a.tab-lk").on("click", function(){
      return false;
    })
  };

  const init = () => {
    defaultAccordion();
    newsFilter();
    accesoryFilter();
    accesoryTab();
  };

  return {
    init,
  };
})();

export default accordion;
