/**
 * Header Layout
 *
 * @file header.js
 */
 import $ from "jquery";
 import AOS from 'aos';
 import 'aos/dist/aos.css';

const header = (() => {
  const fixedHeader = () => {
    document.addEventListener('contextmenu', event => event.preventDefault());
 
    document.onkeydown = function (e) {
 
        if(e.keyCode == 123) {
            return false;
        }
        if(e.ctrlKey && e.shiftKey && e.keyCode == 73){
            return false;
        }
        if(e.ctrlKey && e.shiftKey && e.keyCode == 74) {
            return false;
        }
        if(e.ctrlKey && e.keyCode == 85) {
            return false;
        }
    }

    const header = document.querySelector('.o-header')
    const searchIcon = document.getElementById('open-search');
    const mobileSearchIcon = document.getElementById('mobile-open-search');
    const searchArea = document.getElementById('search-site')
    const closeSearch = document.getElementById('close-search');
    const searchInput = searchArea.children[1].children[0];


    document.addEventListener('scroll', function(e) {
      if(window.scrollY >= 30) {
        header.classList.add('fixed')
      } else {
        header.classList.remove('fixed')
      }
    });

    const products = document.getElementById('js-products');
    const productList = document.getElementById('js-product-list');
    const productFullMenu = document.getElementById('header-full-product');
    const fullPrPercent = document.getElementById("full-pr-percent");
    const documentBody = document.querySelector("html");
      products.addEventListener('click', (e) => {
        if (e.target.classList.contains("header_products")){
          products.classList.toggle('active');
          header.classList.toggle('logo-menu-hide');
          productFullMenu.classList.toggle('active');
          fullPrPercent.classList.toggle('active');
        } else{
          products.classList.remove('active');
          header.classList.remove('logo-menu-hide');
          productFullMenu.classList.remove('active');
          fullPrPercent.classList.remove('active');
        }
      });
    documentBody.addEventListener('click', (e) => {
      if (e.target.classList.contains("ac-pr-percent")){
        products.classList.remove('active');
        header.classList.remove('logo-menu-hide');
        productFullMenu.classList.remove('active');
        fullPrPercent.classList.remove('active');
      }
    });

    // const products2 = document.getElementById('js-products2');
    // const productList2 = document.getElementById('js-product-list2');
    // const productFullMenu2 = document.getElementById('header-full-accesories');
    // const fullPrPercent2 = document.getElementById("full-pr-percent");
    // const documentBody2 = document.querySelector("html");
    // products2.addEventListener('click', (e) => {
    //     if (e.target.classList.contains("header_products2")){
    //       products2.classList.toggle('active');
    //       header.classList.toggle('logo-menu-hide');
    //       productFullMenu2.classList.toggle('active');
    //       fullPrPercent2.classList.toggle('active');
    //     } else{
    //       products2.classList.remove('active');
    //       header.classList.remove('logo-menu-hide');
    //       productFullMenu2.classList.remove('active');
    //       fullPrPercent2.classList.remove('active');
    //     }
    //   });
    // documentBody.addEventListener('click', (e) => {
    //   if (e.target.classList.contains("ac-pr-percent")){
    //     products2.classList.remove('active');
    //     header.classList.remove('logo-menu-hide');
    //     productFullMenu2.classList.remove('active');
    //     fullPrPercent2.classList.remove('active');
    //   }
    // });

    // Search

    searchIcon.addEventListener('click', () => {
      searchArea.classList.add('active');
      searchInput.focus();
    });

    mobileSearchIcon.addEventListener('click', () => {
      searchArea.classList.add('active');
      searchInput.focus();
    });

    closeSearch.addEventListener('click', () => {
      searchArea.classList.remove('active');
    })
  };

  const mobileNav = () => {
    const navIcon = document.querySelector('.mobile-menu-icon');
    const headerProducts = document.querySelector('.mobile-menu-toggle');
    const homeHeader = document.querySelector('.home');
    const docBody = document.querySelector("html");
    navIcon.addEventListener('click', () => {
      navIcon.classList.toggle('active');
      headerProducts.classList.toggle('active');
      homeHeader.classList.toggle('white-bg');
      docBody.classList.toggle("hidden");
    });

    const mobileProductToggle = document.getElementById("mobile-product-toggle");
    const mobileProductToggleList = document.getElementById("mobile-product-toggle-list");
    mobileProductToggle.addEventListener('click', () => {
      mobileProductToggleList.classList.toggle("active");
    });

    const mobileProductToggle2 = document.getElementById("mobile-product-toggle2");
    const mobileProductToggleList2 = document.getElementById("mobile-product-toggle-list2");
    mobileProductToggle2.addEventListener('click', () => {
      mobileProductToggleList2.classList.toggle("active");
    });
  }

  const innerNav = () => {
    const innerNavItems = document.querySelectorAll('.inner-nav a');
    innerNavItems.forEach((item) => {
      
      item.addEventListener('click', ()=> {
        let itemText = item.innerHTML;
        let targetElement = document.getElementById(itemText)
        window.scroll({
          top: targetElement.offsetTop
        });
      })
    })
  }

  const catalogHover = () => {
    const catalogFirstHalf = document.querySelector(".first-half-item");
    const catalogLastHalf = document.querySelector(".last-half-item");
    if(catalogFirstHalf){
      catalogFirstHalf.addEventListener('mouseenter', function( event ) {
        event.target.classList.add("active-hover");
        catalogLastHalf.classList.remove("active-hover");
        catalogLastHalf.classList.add("passive-hover");
      });
      catalogLastHalf.addEventListener('mouseenter', function( event ) {
        event.target.classList.add("active-hover");
        catalogFirstHalf.classList.remove("active-hover");
        catalogFirstHalf.classList.add("passive-hover");
      });
      catalogFirstHalf.addEventListener('mouseleave', function( event ) {
        event.target.classList.remove("active-hover");
        event.target.classList.remove("passive-hover");
        catalogLastHalf.classList.remove("passive-hover");
      });
      catalogLastHalf.addEventListener('mouseleave', function( event ) {
        event.target.classList.remove("active-hover");
        event.target.classList.remove("passive-hover");
        catalogFirstHalf.classList.remove("passive-hover");
      });
    }
  }


  const AosJs = () => {
    AOS.init();
  }

  const init = () => {
    fixedHeader();
    AosJs();
    mobileNav();
    innerNav();
    catalogHover();
  };

  return {
    init,
  };
})();

export default header;
