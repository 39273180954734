/**
 * Lightbox Component
 *
 * @file lightbox.js
 */
import $ from "jquery";
require('fancybox')($);
const goUp = (() => {
    const pageScroll = () => {
        // const breadcrumbScrollPosition = document.getElementById("breadcrumbPosition"); 
        // const pageToTop = document.getElementById("page-scroll-top");
        // const pageToTopValue = breadcrumbScrollPosition.setAttribute("data-top", breadcrumbScrollPosition.offsetTop);
        // const pageToTopGetValue = breadcrumbScrollPosition.getAttribute("data-top");

        // let pageScrollTop = document.documentElement.scrollTop;

        // pageToTop.addEventListener('click', function(e){
        //   window.scrollTo({top: 0, behavior: 'smooth'});
        //   pageToTop.classList.add("hide");
        // });
        // window.onscroll = function (e) { 
        //   console.log(pageToTopGetValue)
        //   if (pageScrollTop > pageToTopGetValue) {
        //     breadcrumbScrollPosition.classList.add("fixed");
        //     pageToTop.classList.remove("hide");
        //     pageToTop.classList.add("show");
        //   } else {
        //     breadcrumbScrollPosition.classList.remove("fixed");
        //     pageToTop.classList.remove("show");
        //     pageToTop.classList.add("hide");
        //   }
        // } 
      };
      const homeCounter = () => {
        if ($('#counter').is(":visible")){
          var a = 0;
          $(window).scroll(function() {
    
            var oTop = $('#counter').offset().top - window.innerHeight;
            if (a == 0 && $(window).scrollTop() > oTop) {
              $('.counter-value').each(function() {
                var $this = $(this),
                  countTo = $this.attr('data-count');
                $({
                  countNum: $this.text()
                }).animate({
                    countNum: countTo
                  },
    
                  {
    
                    duration: 2000,
                    easing: 'swing',
                    step: function() {
                      $this.text(Math.floor(this.countNum));
                    },
                    complete: function() {
                      $this.text(this.countNum);
                    }
    
                  });
              });
              a = 1;
            }
    
          });
        }
      }
  
    const init = () => {
        pageScroll();
        homeCounter();
    };
  
    return {
      init,
    };
  })();
  
  export default goUp;
  

