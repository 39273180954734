/**
 * Slider Component
 *
 * @file slider.js
 */

import $ from "jquery";
import Swiper from 'swiper';
import SwiperCore, { Navigation, Pagination, Thumbs, Mousewheel, EffectCoverflow, EffectFade } from 'swiper/core';
SwiperCore.use([Navigation, Pagination, Thumbs, Mousewheel, EffectCoverflow, EffectFade]);

import 'swiper/swiper-bundle.css';



const slider = (() => {
  /**
   * fullSlider
   * Full width slider settings
   */
  const fullSlider = () => {

    const swiperThumb = new Swiper('.main-slider-thumb', {
      slidesPerView: 3,
      loop: true,
      watchSlidesProgress: true,
      centeredSlides: true
    });

    const swiper = new Swiper('.main-slider', {
      loop: true,
      init: true,
      pagination: {
        el: '.swiper-pagination',
      },
      thumbs: {
        swiper: swiperThumb,
      },
      on: {
        init: function() {
          $('.main-slider__item.swiper-slide-active').find("video").show();
        },
      }
    });
    swiper.on('transitionEnd', function() {
      // console.log('*** mySwiper.realIndex', swiper.activeIndex);
      $('.main-slider__item').find("video").trigger("pause");
      $('.main-slider__item').find("video").hide();
      $('.main-slider__item').eq(swiper.activeIndex).find("video").trigger("play");
      $('.main-slider__item').eq(swiper.activeIndex).find("video").show();
    });
    if($(window).width() < 992){
      $(".main-slider__animated-images, .main-slider__item video, .cloud-cluster").remove();
    }

    function loopingClouds() {
      var imageSize;
      if(window.innerWidth >= 1024) {
        imageSize = 3229; // depends on image size
      } else {
        imageSize = 1536; // depends on image size
      }
    
      var x = 0;
      var y = imageSize;
      var a = 0;
      var b = imageSize;
      var value;
    
      if(window.innerWidth >= 1024) {
        value = 0.13;
      } else if(window.innerWidth >= 768) {
        value = 0.15;
      } else {
        value = 0.1;
      }
      setInterval(function() {
        x-=value;
        y-=value;
        a-=0.2;
        b-=0.2;
    
        $('.cloud-cluster .background .before').css({'transform' : 'translateX(' + -x +'px)'});
        $('.cloud-cluster .background .after').css({'transform' : 'translateX(' + -y +'px)'});
        $('.cloud-cluster .foreground .before').css({'transform' : 'translateX(' + -a +'px)'});
        $('.cloud-cluster .foreground .after').css({'transform' : 'translateX(' + -b +'px)'});
    
        if(x.toString().slice(1) >= imageSize) {
          x = (imageSize);
        }
        if(y.toString().slice(1) >= imageSize) {
          y = (imageSize - 5);
        }
        if(a.toString().slice(1) >= imageSize) {
          a = (imageSize - 5);
        }
        if(b.toString().slice(1) >= imageSize) {
          b = (imageSize - 5);
        }
      }, 10);
    }
    loopingClouds();
    

    

    // swiperThumb.on('slideChange', function () {
    //   swiper.slideTo(swiperThumb.activeIndex);
    // });

    // swiper.on('slideChange', function () {
    //   swiperThumb.slideTo(swiper.activeIndex);
    // });
  };

  const productSlider = () => {

    const productSliderThumb = new Swiper('.product-slider-thumb', {
      loop: true,
      slidesPerView: 4,
      freeMode: false,
      watchSlidesProgress: true,
      allowTouchMove:false,
      pagination: {
        el: '.product-pagination',
        clickable: true
      },
      navigation: {
        nextEl: '.product-slider-next',
        prevEl: '.product-slider-prev',
      },
      breakpoints: {
        0: {
          slidesPerView: 2,
        },
        680: {
          slidesPerView: 4,
        },
      },
    });

    const productSlider = new Swiper('.product-slider', {
      loop: true,
      freeMode: false,
      allowTouchMove:false,
      navigation: {
        nextEl: '.product-slider-next-full',
        prevEl: '.product-slider-prev-full',
      },
      thumbs: {
        swiper: productSliderThumb,
      },
    });

    
    // const paths = document.querySelectorAll('.zoom-product')
    // paths.forEach(Panzoom, {
    //   zoomMin: 0.5, 
    //   zoomMax: 10,
    //   onDoubleClick: function(e) {
    //     return false;
    //   }
    // });
    
    var button3d = document.getElementById('start3d');
    var elem = document.getElementById("myBar");
    var bgimg = document.querySelector('.dArea__container');
    if(button3d) {
      function move() {
        var elem = document.getElementById("myBar");
        var width = 0;
        var id = setInterval(frame, 150);
        function frame() {
          if (width >= 100) {
            clearInterval(id);
          } else {
            width++;
            elem.style.width = width + '%';
            elem.innerHTML = width * 5  + '%';
          }
        }
      }
      
      button3d.addEventListener('click', function() {
        button3d.style.display = 'none';
        elem.style.display = "block";
        move();
        setTimeout(function(){
          elem.style.display = 'none';
          document.querySelector('.deg360').classList.remove('deg360')
          bgimg.style.background = "none";
          window.CI360.init();
        }, 3000)
          
      });
    }
    

    // productSliderThumb.on('slideChange', function () {
    //   productSlider.slideTo(productSliderThumb.activeIndex);
    // });

    var sliders = document.querySelectorAll('.product-slider__item');
    for (var i = 0; i < sliders.length; ++i) {
      sliders[i].addEventListener('click', function(event) {
          event.target.parentNode.parentNode.parentNode.parentNode.classList.add('fullscreen');
          event.target.parentNode.classList.add('zoom-image');
          setTimeout(function() {
            productSlider.update();
            productSliderThumb.update();
          }, 200);
          // var zoomElem = event.target.parentNode.classList("zoom-image");
          // panzoom(zoomElem, {
          //    zoomMin: 0.5, 
          //    zoomMax: 2,
          //    onDoubleClick: function(e) {
          //        return false;
          //        e.preventDefault();
          //        e.stopPropagation();
          //        alert("tolga");
          //    }
          // });
      }, false);
    }

    var closeButtons = document.querySelectorAll('.close-button');
    for (var y = 0; y < closeButtons.length; ++y) {
      closeButtons[y].addEventListener('click', function(event) {
          var fullScreenElements = document.querySelectorAll('.fullscreen');
          for (var x = 0; x < fullScreenElements.length; ++x) {
            fullScreenElements[x].classList.remove('fullscreen');
            setTimeout(function() {
              productSlider.update();
              productSliderThumb.update();
            }, 200);
          }
      });
    }

    // productSlider.on('slideChange', function () {
    //   productSliderThumb.slideTo(productSlider.activeIndex);
    // });
  };

  const featuresSlider = () => {
    const featureSlider = new Swiper('.features-slider', {
      slidesPerView: 3.2,
      spaceBetween: 30,
      pagination: {
        el: '.swiper-pagination',
      },
    });
  }

  const careerSlider = () => {
    const featureSlider = new Swiper('.career-slider', {
      slidesPerView: 3,
      spaceBetween: 30,
      watchSlidesProgress: true,
      pagination: {
        el: '.career-pagination',
        clickable: true
      },
      navigation: {
        nextEl: '.career-slider-next',
        prevEl: '.career-slider-prev',
      },
      breakpoints: {
        0: {
          slidesPerView: 1,
        },
        680: {
          slidesPerView: 2,
          spaceBetween: 30,
        },
        1200: {
          slidesPerView: 3,
          spaceBetween: 30,
        },
      },
    });
  }

  const gallerySlider = () => {
    const galSlider = new Swiper('.gallery-slider', {
      pagination: {
        el: '.gallery-pagination',
        type: "fraction",
      },
      navigation: {
        nextEl: '.gallery-slider-next',
        prevEl: '.gallery-slider-prev',
      }
    });
  }

  const productAniSlider = () => {
    const productAni = document.getElementById("aniToTop");
    if(productAni){
      if($(window).width() < 992) {
        var scrollHeight = 600;
      } else if ($(window).width() < 680) {
        var scrollHeight = 450;
      } else{
        var scrollHeight = 850;
      }
      const productAniContain = document.querySelector(".product-animated-list");
      const productScrollTop = productAni.setAttribute("data-top", parseInt(productAniContain.offsetTop) + parseInt(productAni.offsetTop));
      const productScrollTopValue = productAni.getAttribute("data-top");
      const productScrollTopValueCalc = productScrollTopValue;
      const productAniHeight = parseInt(productScrollTopValue) + parseInt(productAni.offsetHeight);
      const productBottomPage = parseInt(productScrollTopValue) + parseInt(productAni.offsetHeight);

      var productToTop = $("#aniToTop").offset().top - scrollHeight;
      var productToBottom = parseInt(productToTop) + scrollHeight + $("#aniToTop").innerHeight();
      var scrollPause = false;
      $(window).on("scroll", function(){
        // console.log(($(".product-animated-slider").offset().top + 10) + "+" + $(window).scrollTop())
        // if ($(".product-animated-slider").offset().top <=  $(window).scrollTop() && ($(".product-animated-slider").offset().top + 10) >= $(window).scrollTop()){
        //   console.log("aaaaaa");
        //   $("html").addClass("hidden");
        //   $('html,body').stop().animate({scrollTop: $('.product-animated-slider').offset().top});
        // }
        if($(window).scrollTop() > productToTop && $(window).scrollTop() < productToBottom) {
          if(!scrollPause){
            if ($(window).width() < 992) {
              $("html").removeClass("hidden");
            } else{
              $("html").addClass("hidden");
            }
            $('html,body').stop().animate({scrollTop: $('.product-animated-slider').offset().top});
            $(".bg-to-slide").addClass("hide");
            scrollPause = true;
          }
        } else {
          scrollPause = false;
          $(".bg-to-slide").removeClass("hide");
        }
      })
      window.addEventListener("scroll", function(e){
        // if(window.scrollY >= (productScrollTopValueCalc - 100)){
        //     // console.log("yukarıdan");
        //     $(".bg-to-slide").addClass("hide");
        // }
      });
      const productAniSlider = new Swiper('.product-animated-slider', {
        direction: "vertical",
        slidesPerView: 1,
        speed: 1000,
        spaceBetween: 0,
        roundLengths: true,
        effect:"fade",
        mousewheel: {
          forceToAxis: true,
          sensitivity: 1,
          releaseOnEdges: true,
        },
        breakpoints: {
          0: {
            direction: "horizontal",
            navigation: {
              nextEl: '.mobil-ani-slider-next',
              prevEl: '.mobil-ani-slider-prev',
            }
          },
          992: {
            direction: "vertical",
          },
        },
      });
      productAniSlider.on('slideChange', function () {
        if(this.activeIndex === 0) {
          setTimeout(function(){
            if ($(window).width() < 992) {
              $("html").removeClass("hidden");
            } else{
              $("html").removeClass("hidden");
            }
            $(".bg-to-slide").addClass("hide");
          }, 1000);
          scrollPause = true;
        } else {
          scrollPause = false;
          if ($(window).width() < 992) {
            $("html").removeClass("hidden");
          } else{
            $("html").addClass("hidden");
          }
        }
      });
      productAniSlider.on('reachEnd', function(){
        setTimeout(function(){
          $("html").removeClass("hidden");
          $(".bg-to-slide").addClass("hide");
          scrollPause = true;
        }, 1500);
        $(".mobil-ani-slider-next").on("click", function(){
          $('html,body').stop().animate({scrollTop: $('.specs#Specs').offset().top - 70});
        })
      });
    }
  }

  const certificateSlider = () => {
    const galSlider = new Swiper('.quality-c-carousel', {
      slidesPerView: 4,
      spaceBetween: 0,
      pagination: {
        el: '.quality-pagination',
        type: "fraction",
      },
      navigation: {
        nextEl: '.quality-slider-next',
        prevEl: '.quality-slider-prev',
      },
      breakpoints: {
        0: {
          slidesPerView: 1,
        },
        680: {
          slidesPerView: 2,
          spaceBetween: 0,
        },
        1200: {
          slidesPerView: 4,
          spaceBetween: 0,
        },
      },
    });
  }
  
  // const slideTweenLite = () => {
  //   var density = 70,
  //   speed = 2,
  //   winHeight = window.innerHeight,
  //   winWidth = window.innerWidth,
  //   start = {
  //       yMin: winHeight - 50,
  //       yMax: winHeight,
  //       xMin: (winWidth / 2) + 10,
  //       xMax: (winWidth / 2) + 40,
  //       scaleMin: 0.1,
  //       scaleMax: 0.25,
  //       scaleXMin: 0.1,
  //       scaleXMax: 1,
  //       scaleYMin: 1,
  //       scaleYMax: 2,
  //       opacityMin: 0.1,
  //       opacityMax: 0.4
  //   },
  //   mid = {
  //       yMin: winHeight * 0.4,
  //       yMax: winHeight * 0.9,
  //       xMin: winWidth * 0.1,
  //       xMax: winWidth * 0.9,
  //       scaleMin: 0.2,
  //       scaleMax: 0.8,
  //       opacityMin: 1,
  //       opacityMax: 1
  //   },
  //   end = {
  //       yMin: -180,
  //       yMax: -180,
  //       xMin: -100,
  //       xMax: winWidth + 180,
  //       scaleMin: 0.1,
  //       scaleMax: 1,
  //       opacityMin: 1,
  //       opacityMax: 1
  //   };

  // function range(map, prop) {
  //     var min = map[prop + 'Min'],
  //         max = map[prop + 'Max'];
  //     return min + (max - min) * Math.random();
  // }

  // function sign() {
  //     return Math.random() < 0.5 ? -1 : 1;
  // }

  // function randomEase(easeThis, easeThat) {
  //     if (Math.random() < 0.5) {
  //         return easeThat;
  //     }
  //     return easeThis;
  // }

  // function spawn(particle) {
  //     var wholeDuration = (10 / speed) * (0.7 + Math.random() * 0.8),
  //       delay = wholeDuration * Math.random(),
  //       partialDuration = (wholeDuration + 1) * (0.2 + Math.random() * 0.3);
  //       TweenLite.set(particle, {
  //           y: range(start, 'y'),
  //           x: range(start, 'x'),
  //           scaleX: range(start, 'scaleX'),
  //           scaleY: range(start, 'scaleY'),
  //           scale: range(start, 'scale'),
  //           opacity: range(start, 'opacity'),
  //           visibility: 'hidden'
  //       });
  //       // Moving upward
  //       TweenLite.to(particle, partialDuration, {
  //           delay: delay,
  //           y: range(mid, 'y'),
  //           ease: randomEase(Linear.easeOut, Back.easeInOut)
  //       });
  //       TweenLite.to(particle, wholeDuration - partialDuration, {
  //           delay: partialDuration + delay,
  //           y: range(end, 'y'),
  //           ease: Back.easeIn
  //       });
  //       //Moving on axis X
  //       TweenLite.to(particle, partialDuration, {
  //           delay: delay,
  //           x: range(mid, 'x'),
  //           ease: Power1.easeOut
  //       });
  //       TweenLite.to(particle, wholeDuration - partialDuration, {
  //           delay: partialDuration + delay,
  //           x: range(end, 'x'),
  //           ease: Power1.easeIn
  //       });
  //       //opacity and scale
  //       partialDuration = wholeDuration * (1 + Math.random() * 1);
  //       TweenLite.to(particle, partialDuration, {
  //           delay: delay,
  //           scale: range(mid, 'scale'),
  //           autoAlpha: range(mid, 'opacity'),
  //           ease: Linear.easeNone
  //       });
  //       TweenLite.to(particle, wholeDuration - partialDuration, {
  //           delay: partialDuration + delay,
  //           scale: range(end, 'scale'),
  //           autoAlpha: range(end, 'opacity'),
  //           ease: Linear.easeNone,
  //           onComplete: spawn,
  //           onCompleteParams: [particle]
  //       });
  //   }
  //   window.onload = createParticle;

  //   function createParticle() {
  //       var i, particleSpark;
  //       for (i = 0; i < density; i += 1) {
  //           particleSpark = document.createElement('div');
  //           particleSpark.classList.add('spark-item');
  //           document.querySelector(".spark-content").appendChild(particleSpark);
  //           spawn(particleSpark);
  //       }
  //   }
// }

  const init = () => {
    fullSlider();
    productSlider();
    featuresSlider();
    gallerySlider();
    careerSlider();
    productAniSlider();
    certificateSlider();
    // slideTweenLite();
  };

  return {
    init,
  };
})();

export default slider;
